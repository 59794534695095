import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | EXPO Realty
			</title>
			<meta name={"description"} content={"Ваш путь к поиску идеального дома начинается здесь. В EXPO Realty мы понимаем, что каждый клиент уникален"} />
			<meta property={"og:title"} content={"Главная | EXPO Realty"} />
			<meta property={"og:description"} content={"Ваш путь к поиску идеального дома начинается здесь. В EXPO Realty мы понимаем, что каждый клиент уникален"} />
			<meta property={"og:image"} content={"https://blinqez.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blinqez.com/img/451029412.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blinqez.com/img/451029412.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blinqez.com/img/451029412.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blinqez.com/img/451029412.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blinqez.com/img/451029412.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blinqez.com/img/451029412.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
					Добро пожаловать в EXPO Realty!
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197" lg-margin="0px 0px 30px 0px">
					Ваш путь к поиску идеального дома начинается здесь. В EXPO Realty мы понимаем, что каждый клиент уникален, у него своя история и свои мечты. Покупаете ли вы, продаете или арендуете жилье, наша команда готова сопровождать вас на каждом шагу. Мы не просто заключаем сделки - мы строим прочные отношения и создаем сообщества.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Будьте в курсе событий
						</Button>
					</Box>
				</Box>
				<Image
					src="https://blinqez.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 30px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Почему выбирают нас?
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blinqez.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Местная экспертиза, глобальный охват
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Наши агенты имеют глубокие корни в обществе, предлагая непревзойденные знания в сочетании с широкой сетью для удовлетворения ваших потребностей.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Индивидуальный подход
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Мы гордимся тем, что подбираем наши услуги в соответствии с вашими индивидуальными требованиями, обеспечивая бесперебойную работу от начала и до конца.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blinqez.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://blinqez.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Честность и прозрачность
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Честность - наша основа. Мы верим в четкую коммуникацию и этические нормы во всех наших сделках.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});